import { getMaintenanceTypes } from "src/redux/action/types/maintenancemanagement/getMaintenanceTypes"

const initialState = {
    isLoading:false,
    // isError:false,
    data:[],
    error:null
}

const maintenanceManagementReducer = (state=initialState,action)=>{
    switch(action.type){
        case getMaintenanceTypes.GET_MAINTENANCE:
            {
                return{
                    ...state,
                    isLoading:true 
                }
            }
        case getMaintenanceTypes.GET_MAINTENANCE_SUCCESS:
            {
                // console.log('response reducer',action.payload)
                return{
                    ...state,
                    isLoading:false,
                    data:action.payload
                }

            }
        case getMaintenanceTypes.GET_MAINTENANCE_FAILED:
            {
                return{
                    ...state,
                    isLoading:false,
                    data:[],
                    error:action.payload
                }
            }
        default : return {
            ...state
        }
    }
}
export default maintenanceManagementReducer;