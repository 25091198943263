import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetVoucherTemplates from "src/api/generatevoucherAPI/getVoucherTemplateAPI";

function* handleGetVoucherTemplates() {
  try {
    const vtemplates = yield call(fetchGetVoucherTemplates);
    yield put({ type: "GET_VTEMPLATES_SUCCESS", vtemplates: vtemplates });
  } catch (err) {
    yield put({ type: "GET_VTEMPLATES_FAILED", message: err.message });
  }
}

function* watcherVoucherTemplateSaga() {
  yield takeLatest("GET_VTEMPLATES_REQUEST", handleGetVoucherTemplates);
}

export default watcherVoucherTemplateSaga;