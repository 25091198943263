const initialState = {
    skinParts: [],
    loading: false,
    error: null,
  };
  
  const skinParts = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SKIN_PARTS_REQUEST":
        return { ...state, loading: true };
      case "GET_SKIN_PARTS_SUCCESS":
        return { ...state, loading: false, skinParts: action.skinParts };
      case "GET_SKIN_PARTS_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default skinParts;