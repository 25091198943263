import { getNewsTypes } from "src/redux/action/types/newsmanagement/getNewsTypes"

const initialState = {
    isLoading:false,
    // isError:false,
    data:[],
    error:null
}

const newsManagementReducer = (state=initialState,action)=>{
    switch(action.type){
        case getNewsTypes.GET_NEWS:
            {
                return{
                    ...state,
                    isLoading:true 
                }
            }
        case getNewsTypes.GET_NEWS_SUCCESS:
            {
                // console.log('response reducer',action.payload)
                return{
                    ...state,
                    isLoading:false,
                    data:action.payload
                }

            }
        case getNewsTypes.GET_NEWS_FAILED:
            {
                return{
                    ...state,
                    isLoading:false,
                    data:[],
                    error:action.payload
                }
            }
        default : return {
            ...state
        }
    }
}
export default newsManagementReducer;