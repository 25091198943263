const initialState = {
    vtemplates: [],
    loading: false,
    error: null,
  };
  
  const vtemplates = (state = initialState, action) => {
    switch (action.type) {
      case "GET_VTEMPLATES_REQUEST":
        return { ...state, loading: true };
      case "GET_VTEMPLATES_SUCCESS":
        return { ...state, loading: false, vtemplates: action.vtemplates };
      case "GET_VTEMPLATES_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default vtemplates;