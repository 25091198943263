const initialState = {
    ksatriyas: [],
    loading: false,
    error: null,
  };
  
  const ksatriyas = (state = initialState, action) => {
    switch (action.type) {
      case "GET_KSATRIYAS_REQUEST":
        return { ...state, loading: true };
      case "GET_KSATRIYAS_SUCCESS":
        return { ...state, loading: false, ksatriyas: action.ksatriyas };
      case "GET_KSATRIYAS_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default ksatriyas;