import { API_BASE } from "src/config/api";

const header = {
  headers: {
    method: "GET",
  },
};

export const maintenanceManagementApi = () => {
  return API_BASE.get("api/maintenance/getAllMaintenance", header);
};
