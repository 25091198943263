import axios from "axios";

export const API_BASE = axios.create({
  baseURL: "",
});

API_BASE.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("auth");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});
