import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetKsatriyas from "src/api/mailAPI/attachmentsApi/getKsatriyasApi";

function* handleGetKsatriyas() {
  try {
    const ksatriyas = yield call(fetchGetKsatriyas);
    yield put({ type: "GET_KSATRIYAS_SUCCESS", ksatriyas: ksatriyas });
  } catch (err) {
    yield put({ type: "GET_KSATRIYAS_FAILED", message: err.message });
  }
}

function* watcherKsatriyasSaga() {
  yield takeLatest("GET_KSATRIYAS_REQUEST", handleGetKsatriyas);
}

export default watcherKsatriyasSaga;