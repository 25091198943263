import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetKsatriyaFragments from "src/api/mailAPI/attachmentsApi/getKsatriyaFragmentApi";


function* handleGetKsatriyaFragments() {
  try {
    const ksatriyaFragments = yield call(fetchGetKsatriyaFragments);
    yield put({ type: "GET_KSATRIYA_FRAGMENT_SUCCESS", ksatriyaFragments: ksatriyaFragments });
  } catch (err) {
    yield put({ type: "GET_KSATRIYA_FRAGMENT_FAILED", message: err.message });
  }
}

function* watcherKsatriyaFragmentSaga() {
  yield takeLatest("GET_KSATRIYA_FRAGMENT_REQUEST", handleGetKsatriyaFragments);
}

export default watcherKsatriyaFragmentSaga;