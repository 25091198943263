const { loginApi } = require("src/api/loginApi");
const { loginTypes } = require("../action/types/loginTypes");
const { takeLatest, put } = require("redux-saga/effects");

function* loginWatcher() {
  yield takeLatest(loginTypes.LOGIN_REQUEST, loginWorker);
}

function* loginWorker(action) {
  try {
    const response = yield loginApi(action.payload);
    localStorage.setItem("auth", response.data?.token);
    yield put({
      type: loginTypes.LOGIN_SUCCESS,
      payload: response.data.token,
    });
  } catch (error) {
    yield put({
      type: loginTypes.LOGIN_FAILED,
      payload: error,
    });
  }
}

export default loginWatcher;
