import axios from "axios";
import { API_BASE } from "src/config/api";
import * as signalR from "@microsoft/signalr";
import saveTokenToCookies from "src/services/cookies/savedToCookies";
import getApiDomain from "src/services/getApiDomain";

export const getCurrentUserLogin = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("auth"),
    },
  };
  return axios.get(`/api/getCurrentUserLogin`, config);
};

export function signalRConnection(token, setConnection, setIsLoading) {
  const debug = signalR.LogLevel.Debug;

  const baseUrl = getApiDomain();

  const authToken = encodeURI(token);
  const userId = "3";
  const chatHistory = 20;

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(
      `${baseUrl}/hub/globalchat?userId=${userId}&authToken=${authToken}&history=${chatHistory}`,
      {
        transport: signalR.HttpTransportType.WebSockets,
      }
    )
    .configureLogging(debug)
    .withAutomaticReconnect()
    .build();
  setIsLoading(false);
  setConnection(connection);
}

export const generateAuthToken = (setData, setConnection, setIsLoading) => {
  setIsLoading(true);

  API_BASE.get(`/api/login/getAuthToken`).then((res) => {
    const data = res.data;
    setData(data);

    const token = data?.token;
    signalRConnection(token, setConnection, setIsLoading);

    // save to cookies
    saveTokenToCookies("token", token);
  });
};
