import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetAvatars from "src/api/mailAPI/attachmentsApi/getAvatarApi";

function* handleGetAvatars() {
  try {
    const avatars = yield call(fetchGetAvatars);
    yield put({ type: "GET_AVATARS_SUCCESS", avatars: avatars });
  } catch (err) {
    yield put({ type: "GET_AVATARS_FAILED", message: err.message });
  }
}

function* watcherAvatarsSaga() {
  yield takeLatest("GET_AVATARS_REQUEST", handleGetAvatars);
}

export default watcherAvatarsSaga;