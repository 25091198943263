const { newsManagementApi } = require("src/api/newsmanagement/newsManagementApi");
const { getNewsTypes } = require("src/redux/action/types/newsmanagement/getNewsTypes");
const { takeLatest,put } = require('redux-saga/effects');

function* getNewsSaga (){
    yield takeLatest (getNewsTypes.GET_NEWS,getNews)
}

function* getNews (action){
    try {
        const response = yield newsManagementApi()
        // console.log('response saga',response.data)
        yield put ({
            type:getNewsTypes.GET_NEWS_SUCCESS,payload:response.data
        })
    } catch (error) {
        yield put ({
            type:getNewsTypes.GET_NEWS_FAILED,payload:error
        })
    }
}

export default getNewsSaga;