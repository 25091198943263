const initialState = {
    runes: [],
    loading: false,
    error: null,
  };
  
  const runes = (state = initialState, action) => {
    switch (action.type) {
      case "GET_RUNES_REQUEST":
        return { ...state, loading: true };
      case "GET_RUNES_SUCCESS":
        return { ...state, loading: false, runes: action.runes };
      case "GET_RUNES_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default runes;