import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetRunes from "src/api/mailAPI/attachmentsApi/getRunesApi";

function* handleGetRunes() {
  try {
    const runes = yield call(fetchGetRunes);
    yield put({ type: "GET_RUNES_SUCCESS", runes: runes });
  } catch (err) {
    yield put({ type: "GET_RUNES_FAILED", message: err.message });
  }
}

function* watcherRunesSaga() {
  yield takeLatest("GET_RUNES_REQUEST", handleGetRunes);
}

export default watcherRunesSaga;