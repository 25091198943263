import { currentInfoTypes } from "src/redux/action/types/currentInfoTypes/currentInfoTypes"

const initialState = {
    isLoading:false,
    // isError:false,
    data:[],
    error:null
}

const currentInfoReducer = (state=initialState,action)=>{
    switch(action.type){
        case currentInfoTypes.GET_INFO:
            {
                return{
                    ...state,
                    isLoading:true 
                }
            }
        case currentInfoTypes.GET_INFO_SUCCESS:
            {
                // console.log('response reducer',action.payload)
                return{
                    ...state,
                    isLoading:false,
                    data:action.payload
                }

            }
        case currentInfoTypes.GET_INFO_FAILED:
            {
                return{
                    ...state,
                    isLoading:false,
                    data:[],
                    error:action.payload
                }
            }
        default : return {
            ...state
        }
    }
}
export default currentInfoReducer;