import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetRoles from "src/api/rolesManagementApi/rolesManagementApi";

function* handleGetRoles() {
  try {
    const roles = yield call(fetchGetRoles);
    yield put({ type: "GET_ROLES_SUCCESS", roles: roles });
  } catch (err) {
    yield put({ type: "GET_ROLES_FAILED", message: err.message });
  }
}

function* watcherRolesSaga() {
  yield takeLatest("GET_ROLES_REQUEST", handleGetRoles);
}

export default watcherRolesSaga;