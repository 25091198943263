import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetFrames from "src/api/mailAPI/attachmentsApi/getFramesApi";

function* handleGetFrames() {
  try {
    const frames = yield call(fetchGetFrames);
    yield put({ type: "GET_FRAMES_SUCCESS", frames: frames });
  } catch (err) {
    yield put({ type: "GET_FRAMES_FAILED", message: err.message });
  }
}

function* watcherFramesSaga() {
  yield takeLatest("GET_FRAMES_REQUEST", handleGetFrames);
}

export default watcherFramesSaga;