const initialState = {
    boxes: [],
    loading: false,
    error: null,
  };
  
  const boxes = (state = initialState, action) => {
    switch (action.type) {
      case "GET_BOXES_REQUEST":
        return { ...state, loading: true };
      case "GET_BOXES_SUCCESS":
        return { ...state, loading: false, boxes: action.boxes };
      case "GET_BOXES_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default boxes;