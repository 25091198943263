const initialState = {
    players: [],
    loading: false,
    error: null,
  };
  
  const players = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PLAYERS_REQUEST":
        return { ...state, loading: true };
      case "GET_PLAYERS_SUCCESS":
        return { ...state, loading: false, players: action.players };
      case "GET_PLAYERS_FAILED":
        return { ...state, loading: false, error: action.message };
      default:
        return state;
    }
  };
  
  export default players;