import { call, put, takeLatest } from "redux-saga/effects";
import fetchGetPlayers from "src/api/mailAPI/getPlayersApi";

function* handleGetPlayers() {
  try {
    const players = yield call(fetchGetPlayers);
    yield put({ type: "GET_PLAYERS_SUCCESS", players: players });
  } catch (err) {
    yield put({ type: "GET_PLAYERS_FAILED", message: err.message });
  }
}

function* watcherPlayersSaga() {
  yield takeLatest("GET_PLAYERS_REQUEST", handleGetPlayers);
}

export default watcherPlayersSaga;